/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function () {
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        // Mobile Navigation
        $('.c-toggle__nav').click(function (e) {
          $(this).toggleClass('is-active');

          $('.c-nav__mobile .c-nav__list').slideToggle();
        });



        
        // JavaScript to be fired on the home page, after the init JS
        // Slick
        if ($('.js-carousel-gallery').length) {
          $('.js-carousel-gallery').slick({
            arrows: true,
            asNavFor: '.js-carousel-nav',
            dots: false,
            fade: true,
            infinite: false,
            loop: false,
            nextArrow: '<div class="next mr-15"><i class="fas fa-chevron-right text-white"></i></div>',
            prevArrow: '<div class="prev ml-15"><i class="fas fa-chevron-left text-white"></i></div>',
            rows: 0,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
          });
        }

        if ($('.js-carousel-nav').length) {
          $('.js-carousel-nav').slick({
            arrows: false,
            asNavFor: '.js-carousel-gallery',
            autoplay: true,
            dots: false,
            focusOnSelect: true,
            rows: 0,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            loop: false,
          });
        }


        // Slick Homes for sale gallery
        if ($('.js-sales').length) {
          $('.js-sales').slick({
            arrows: true,
            nextArrow: '<div class="next mr-15"><i class="fas fa-chevron-right text-white"></i></div>',
            prevArrow: '<div class="prev ml-15"><i class="fas fa-chevron-left text-white"></i></div>',
            dots: false,
            fade: false,
            rows: 0,
            autoplay: true,
            autoplaySpeed: 15000,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 1,

            responsive: [
              {
                breakpoint: 1023,
                settings: {
                  arrows: true,
                  centerPadding: '5%',
                  dots: false,
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 567,
                settings: {
                  arrows: true,
                  centerPadding: '5%',
                  dots: false,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          });
        }



        // Filter Communities
        $('.all-properties').click(function() {
          $('.all-communities').removeClass('current_page_item');
          $('.all-resorts').removeClass('current_page_item');
          $(this).addClass('current_page_item');
          $('.display-all').removeClass('hide-me');
          $('.display-communities').addClass('hide-me');
          $('.display-resorts').addClass('hide-me');
        });

        $('.all-communities').click(function() {
          $('.all-properties').removeClass('current_page_item');
          $('.all-resorts').removeClass('current_page_item');
          $(this).addClass('current_page_item');
          $('.display-all').addClass('hide-me');
          $('.display-communities').removeClass('hide-me');
          $('.display-resorts').addClass('hide-me');
        });

        $('.all-resorts').click(function() {
          $('.all-properties').removeClass('current_page_item');
          $('.all-communities').removeClass('current_page_item');
          $(this).addClass('current_page_item');
          $('.display-all').addClass('hide-me');
          $('.display-communities').addClass('hide-me');
          $('.display-resorts').removeClass('hide-me');
        });

        //
        //Callout Section
        //


        // Mobile Menu
        $('.c-toggle--nav').click(function (e) {
          e.preventDefault();

          $('.c-nav--mobile, .c-toggle--nav').toggleClass('is-active');
        });

        if ($('.js-carousel-properties').length) {
          $('.js-carousel-properties').slick({
            accessibility: true,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: true,
            centerMode: true,
            centerPadding: '10%',
            dots: false,
            fade: false,
            pauseOnFocus: false,
            pauseOnHover: true,
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z" fill="#0061ab" /></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z" fill="#0061ab" /></svg></button>',
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
              {
                breakpoint: 1023,
                settings: {
                  arrows: false,
                  centerPadding: '5%',
                  dots: false,
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 567,
                settings: {
                  arrows: false,
                  centerPadding: '5%',
                  dots: false,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          });
        }

        if ($('.js-carousel-videos').length) {
          $('.js-carousel-videos').slick({
            accessibility: true,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: true,
            centerMode: true,
            centerPadding: '10%',
            dots: true,
            fade: false,
            pauseOnFocus: false,
            pauseOnHover: true,
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z" fill="#0061ab" /></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z" fill="#0061ab" /></svg></button>',
            speed: 1000,
            slidesToShow: 2,
            slidesToScroll: 2,
            responsive: [
              {
                breakpoint: 1023,
                settings: {
                  arrows: false,
                  centerPadding: '5%',
                  dots: true,
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 567,
                settings: {
                  arrows: false,
                  centerPadding: '5%',
                  dots: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          });
        }





        // Find all YouTube videos
        // Expand that selector for Vimeo and whatever else
        var $allVideos = $("iframe[src^='https://www.youtube.com']"),

        // The element that is fluid width
        $fluidEl = $(".carousel-item--video");

        // Figure out and save aspect ratio for each video
        $allVideos.each(function () {

          $(this)
          .data('aspectRatio', this.height / this.width)

          // and remove the hard coded width/height
          .removeAttr('height')
          .removeAttr('width');

        });

        // When the window is resized
        $(window).resize(function () {

          var newWidth = $fluidEl.width();

          // Resize all videos according to their own aspect ratio
          $allVideos.each(function () {

            var $el = $(this);
            $el
            .width(newWidth)
            .height(newWidth * $el.data('aspectRatio'));

          });

          // Kick off one resize to fix all videos on page load
        }).resize();

        if ($('.js-filter-state').length) {
          $('.js-filter-state').on('change', function () {
            var value = $(this).val();

            if (value === 'all') {
              $('.carousel-item').show();
            } else {
              $('.carousel-item').hide();

              $('[data-state="' + value + '"]').show();
            }
          });
        }
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(window).on('load', function () {
          if ($('popup-overlay, .popup-container').length) {
            $('.popup-overlay, .popup-container').addClass('active');
            //alert('hello');
          }
          $('fancybox-close-small, .popup-overlay').on('click', function () {
            $('.popup-overlay, .popup-container').removeClass('active');
          });
        });
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
